import { Controller } from 'stimulus';
import autocomplete from 'js-autocomplete/auto-complete.min.js';

export default class extends Controller {
  static targets = ['monsterName', 'searchField', 'monsterTypeField'];

  connect() {
    let currentUrl = window.location.href;
    if (currentUrl.match(/\/monsters\//)) {
      let url = currentUrl.split('?')[0]
      let urlParts = url.split('/');
      let indexToReplace = urlParts.indexOf('monsters') + 1;
      let urlSlug = urlParts[indexToReplace];
      let monsterNameTarget = this['monsterNameTarget']
      let dataSlug = monsterNameTarget.dataset.slug;
      if (urlSlug !== dataSlug) {
        window.location.replace(currentUrl.replace(urlSlug, dataSlug));
      }
    }
  }

  toggleFilter(event) {
    let typeField = this['monsterTypeFieldTarget'];
    if (event.currentTarget.className.includes('monsters__filter-image-container--selected')) {
      typeField.value = '';
      typeField.disabled = true;
    } else {
      let monsterType = event.currentTarget.dataset.monsterType;
      typeField.value = monsterType;
      typeField.disabled = false;
    }
    typeField.form.submit();
  }
}
