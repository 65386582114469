import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['payButton'];

  clickPayButton(event) {
    if (this.payButtonTarget) {
      event.preventDefault();
      this.payButtonTarget.click();
    }
  }
}
