import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['illustrationGallery', 'illustrationGalleryFeature']

  openIllustrationGallery(event) {
    let gallery = this['illustrationGalleryTarget']
    gallery.classList.remove('hidden')
  }

  closeIllustrationGallery(event) {
    let gallery = this['illustrationGalleryTarget']
    gallery.classList.add('hidden')
  }

  selectIllustration(event) {
    let illustrationContainer = event.target.closest('.illustration__container');
    let containers = illustrationContainer.parentNode.children;
    for (var container of containers) { container.classList.remove('illustration__container--selected'); }
    illustrationContainer.classList.add('illustration__container--selected');
    let feature = this['illustrationGalleryFeatureTarget']
    let featureIllustrations = feature.getElementsByClassName('gallery__feature-content')
    for (var featureIllustration of featureIllustrations) {
      if (featureIllustration.id == 'monster_illustration_' + event.currentTarget.dataset.featureIllustrationId) {
        featureIllustration.classList.remove('hidden')
      } else {
        featureIllustration.classList.add('hidden')
      }
    }
  }
}
