import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['armorClassSelect', 'shieldArmorClass'];

  toggleArmorClassSelect(event) {
    if (event.target.value == 'shield') {
      this.armorClassSelectTarget.classList.add('hidden')
      this.shieldArmorClassTarget.classList.remove('hidden')
    } else {
      this.armorClassSelectTarget.classList.remove('hidden')
      this.shieldArmorClassTarget.classList.add('hidden')
    }
  }
}
