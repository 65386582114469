import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'nameOnCard'];

  connect() {
    let cardElement = this['cardElementTarget']

    if (cardElement) {
      this.setUpStripe()
      this.setUpErrors()
    }
  }

  setUpStripe() {
    const stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute('content')
    this.stripe = Stripe(stripe_key)
    const elements = this.stripe.elements()

    this.card = elements.create('card')
    this.card.mount('#card-element')
  }

  setUpErrors() {
    // Setup up errors
    this.cardErrorsElement = this['cardErrorsTarget']
    this.card.addEventListener('change', (event) => {
      if (event.error) {
        this.cardErrorsElement.textContent = event.error.message
      } else {
        this.cardErrorsElement.textContent = ''
      }
    })
    const form = document.querySelector('#payment-form')
    const paymentIntentId = form.dataset.paymentIntent
    if (paymentIntentId) {
      if (form.dataset.status == 'requires_action') {
        this.stripe.confirmCardPayment(paymentIntentId, { setup_future_usage: 'off_session' }).then((result) => {
          if (result.error) {
            this.cardErrorsElement.textContent = result.error.message
            form.querySelector('#card-details').classList.remove('d-none')
          } else {
            form.submit()
          }
        })
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.target
    const nameField = this['nameOnCardTarget']
    let dataForStripe = {
      payment_method_data: {
        card: this.card,
        billing_details: {
          name: nameField.value
        }
      }
    }

    const paymentIntentId = form.dataset.paymentIntent

    if (paymentIntentId) {
      this.stripe.confirmCardPayment(paymentIntentId, {
        payment_method: dataForStripe.payment_method_data,
        setup_future_usage: 'off_session',
      }).then((result) => {
        if (result.error) {
          this.cardErrorsElement.textContent = result.error.message
        } else {
          console.log('* * * card submit handled successfully')
          console.log(result)
          console.log(result.paymentIntent)
          form.submit()
        }
      })
    }
  }
}
