import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['illustration', 'spinner'];

  spin() {
    let illustration = this['illustrationTarget'];
    illustration.classList.add('hidden')
    let spinner = this['spinnerTarget'];
    spinner.classList.remove('hidden')
  }
}
