import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.scoreBlockName = 'ability-set__ability'
    this.scoreBlockSelector = `.${this.scoreBlockName}`
    this.dieBlockName = 'generated-ability-die'
    this.dieBlockSelector = `.${this.dieBlockName}`
  }

  scoreElement(event) { return event.target.closest(this.scoreBlockSelector) }
  dieElement(event) { return event.target.closest(this.dieBlockSelector) }

  dragStartScore(event) {
    console.log('dragStartScore')
    document.draggedType = 'ability'
    event.dataTransfer.setData('text/plain', event.target.id)
    this.addModifierToScore(this.scoreElement(event), 'dragging')
  }

  dragEnterScore(event) {
    console.log('dragEnterScore')
    if (document.draggedType == 'ability') {
      if (!this.scoreContainsModifier(this.scoreElement(event), 'dragging')) {
        this.addModifierToScore(this.scoreElement(event), 'dragging-over')
      }
    }
  }

  dragLeaveScore(event) {
    console.log('dragLeaveScore')
    this.removeModifierFromScore(this.scoreElement(event), 'dragging-over')
  }

  dragOverScore(event) {
    console.log('dragOverScore')
    event.preventDefault() // Necessary to allow dropping
  }

  dragEndScore(event) {
    console.log('dragEndScore')
    this.removeDragStyling(event)
  }

  // triggered when a draggable is dropped on a score
  dropScore(event) {
    console.log('dropScore')
    event.preventDefault()
    let draggedElementId = event.dataTransfer.getData("text/plain")
    if (draggedElementId.match(/generated_ability_die/)) {
      draggedElementId = document.getElementById(draggedElementId).closest(this.scoreBlockSelector).id
    } else {
      this.swapScores(draggedElementId, this.scoreElement(event))
    }
    this.removeDragStyling(event)
  }

  dragStartDie(event) {
    console.log('dragStartDie')
    event.dataTransfer.setData("text/plain", event.target.id)
    document.draggedType = 'die'
    this.addModifierToDie(this.dieElement(event), 'dragging')
    event.stopPropagation()
  }

  dragEnterDie(event) {
    console.log('dragEnterDie')
    if (document.draggedType == 'die') {
      if (!this.dieContainsModifier(this.dieElement(event), 'dragging')) {
        this.addModifierToDie(this.dieElement(event), 'dragging-over')
      }
    } else {
      if (!this.scoreContainsModifier(this.scoreElement(event), 'dragging')) {
        this.addModifierToScore(this.scoreElement(event), 'dragging-over')
      }
    }
    event.stopPropagation()
  }

  dragLeaveDie(event) {
    console.log('dragLeaveDie')
    this.removeModifierFromDie(this.dieElement(event), 'dragging-over')
    event.stopPropagation()
  }

  dragOverDie(event) {
    console.log('dragOverDie')
    if (!this.scoreContainsModifier(this.scoreElement(event), 'dragging')) {
      this.addModifierToScore(this.scoreElement(event), 'dragging-over')
    }
    event.preventDefault() // Necessary to allow dropping
    event.stopPropagation()
  }

  dragEndDie(event) {
    console.log('dragEndDie')
    this.removeDragStyling(event)
  }

  // triggered when a draggable is dropped on a die
  dropDie(event) {
    console.log('dropDie')
    event.preventDefault()
    let draggedElementId = event.dataTransfer.getData("text/plain")
    if (document.draggedType == 'ability') {
      this.swapScores(draggedElementId, this.scoreElement(event))
    } else {
      this.callServerToSwapDice(
        draggedElementId.replace(/generated_ability_die_/, ''),
        this.dieElement(event).id.replace(/generated_ability_die_/, '')
      )
      this.addModifierToDie(this.dieElement(event), 'transition')
    }
    this.removeDragStyling(event)
    event.stopPropagation()
  }

  swapScores(fromScoreId, toScoreElement) {
    console.log(`${fromScoreId} was dragged to ${toScoreElement.id}`)
    this.addModifierToScore(toScoreElement, 'transition')
    this.updateServer(
      fromScoreId.replace(/ability-/, ''),
      toScoreElement.id.replace(/ability-/, '')
    )
  }

  removeDragStyling(event) {
    let container = event.target.closest('.ability-set__section')
    let recipientElement = event.target.closest(this.scoreBlockSelector)

    container.querySelectorAll(this.scoreBlockSelector).forEach(abilityTag => {
      this.removeModifierFromScore(abilityTag, 'dragging')
      this.removeModifierFromScore(abilityTag, 'dragging-over')
      setTimeout(() => {
        abilityTag.addEventListener('transitionend', () => {
          this.removeModifierFromScore(recipientElement, 'transition');
        });
      }, 0);

      abilityTag.querySelectorAll(this.dieBlockSelector).forEach(dieTag => {
        this.removeModifierFromDie(dieTag, 'dragging')
        this.removeModifierFromDie(dieTag, 'dragging-over')
        setTimeout(() => {
          dieTag.addEventListener('transitionend', () => {
            this.removeModifierFromDie(recipientElement, 'transition');
          });
        }, 0);
      })
    })
  }

  addModifierToScore(element, modifier) {
    element.classList.add(this.scoreClassWithModifier(modifier))
  }

  removeModifierFromScore(element, modifier) {
    element.classList.remove(this.scoreClassWithModifier(modifier))
  }

  addModifierToDie(element, modifier) {
    element.classList.add(this.dieClassWithModifier(modifier))
  }

  removeModifierFromDie(element, modifier) {
    element.classList.remove(this.dieClassWithModifier(modifier))
  }

  scoreContainsModifier(element, modifier) {
    return element.classList.contains(this.scoreClassWithModifier(modifier))
  }

  dieContainsModifier(element, modifier) {
    return element.classList.contains(this.dieClassWithModifier(modifier))
  }

  scoreClassWithModifier(modifier) {
    return `${this.scoreBlockName}--${modifier}`
  }

  dieClassWithModifier(modifier) {
    return `${this.dieBlockName}--${modifier}`
  }

  updateServer(fromAbilityKey, toAbilityKey) {
    if (fromAbilityKey == toAbilityKey) { return }

    console.log('call abilitySwaps#create with params', fromAbilityKey, toAbilityKey)
    let characterId = document.querySelector('.character').id.replace(/character\-/, '')
    let url = `/characters/${characterId}/ability_swaps`
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        ability_swap: {
          from_ability_key: fromAbilityKey,
          to_ability_key: toAbilityKey,
        }
      })
    })
      .then(response => response.text())
      .then(Turbo.renderStreamMessage)
  }

  callServerToSwapDice(fromDieId, toDieId) {
    console.log('call dieSwaps#create with params', fromDieId, toDieId)
    let characterId = document.querySelector('.character').id.replace(/character\-/, '')
    let url = `/characters/${characterId}/die_swaps`
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ die_swap: { from_die_id: fromDieId, to_die_id: toDieId } })
    })
      .then(response => response.text())
      .then(Turbo.renderStreamMessage)
  }
}
