import { Controller } from 'stimulus';

export default class extends Controller {
  toggleEditor(event) {
    let group = event.target.closest('.combat__combatants-table-row-group');
    let editor = group.getElementsByClassName('combat__combatant-editor')[0]
    if (editor) {
      let editorTypes = group.getElementsByClassName('combat__combatant-editor-type')
      Array.from(editorTypes).forEach((element) => {
        element.classList.add('hidden')
      });
      let editorType = event.currentTarget.dataset.editor
      editorType ||= 'hit-points'
      let form = editor.getElementsByClassName('combat__combatant-editor-type--' + editorType)[0]
      if (form) {
        form.classList.remove('hidden')
        editor.classList.toggle('hidden')
        let firstField = form.querySelectorAll('select, input[type=number], input[type=text]')[0]
        if (firstField) { firstField.focus() }
      } else {
        editor.classList.toggle('hidden')
      }
    }
    event.preventDefault()
  }

  submit(event) {
    // TODO: why am I calling closest twice?
    let form = event.target.closest('form')
    form.closest('form').submit()
  }

  toggle(event) {
    let combatantRow = event.target.closest('.combat__combatant')
    if (combatantRow.classList.contains('combat__combatant--edit-mode')) {
      combatantRow.querySelector('.combat__combatant-edit-form').submit()
    } else {
      combatantRow.classList.toggle('combat__combatant--edit-mode')
    }
  }

  toggleLevelAndChallenge(event) {
    let form = event.target.closest('form')
    let characterLevelField = form.getElementsByClassName('combat__combatant-editor-field--character-level')[0].getElementsByTagName('input')[0]
    let challengeRatingField = form.getElementsByClassName('combat__combatant-editor-field--challenge-rating')[0].getElementsByTagName('select')[0]
    if (event.target.value == '1') {
      characterLevelField.disabled = false
      challengeRatingField.disabled = true
    } else {
      characterLevelField.disabled = true
      challengeRatingField.disabled = false
    }
  }

  removeFromCombat(event) {
    let hiddenField = event.target.parentElement.getElementsByClassName('combatant__out-of-combat-field')[0]
    hiddenField.value = true
    hiddenField.closest('form').submit()
  }

  moveIntoCombat(event) {
    let hiddenField = event.target.parentElement.getElementsByClassName('combatant__out-of-combat-field')[0]
    hiddenField.value = false
    hiddenField.closest('form').submit()
  }
}
