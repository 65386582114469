import { Controller } from 'stimulus';
import autocomplete from 'js-autocomplete/auto-complete.min.js';
export default class extends Controller {
  static targets = [
    'charismaModifier',
    'constitutionModifier',
    'dexterityModifier',
    'hitPoints',
    'intelligenceModifier',
    'monsterIllustrationEditor',
    'secondaryDamageAbilityAbbreviation',
    'secondaryDamageDamageType',
    'secondaryDamageDieSize',
    'secondaryDamageNumDice',
    'specialTraitEditorDescription',
    'speedHover',
    'spellAttackRange',
    'spellAttackReach',
    'spellsLevel1',
    'spellsLevel2',
    'spellsLevel3',
    'spellsLevel4',
    'spellsLevel5',
    'spellsLevel6',
    'spellsLevel7',
    'spellsLevel8',
    'spellsLevel9',
    'strengthModifier',
    'telepathySharedLanguage',
    'weaponAttackLongRange',
    'weaponAttackReach',
    'weaponAttackNormalRange',
    'wisdomModifier',
  ]

  openEditor(event) {
    let editor = this[event.currentTarget.dataset.editor + 'EditorTarget']
    editor.classList.remove('hidden')
    let firstField = editor.querySelector('input[type=text]') ||
      editor.querySelector('input[type=number]') ||
      editor.querySelector('textarea')
    if (firstField) { firstField.focus() }
  }

  closeEditor(event) {
    let editor = this[event.currentTarget.dataset.editor + 'EditorTarget']
    editor.classList.add('hidden')
  }

  cancel(event) {
    location.reload()
    event.preventDefault()
  }

  toggleDisabled(event) {
    let targetNames = event.currentTarget.dataset.element.split(' ');
    targetNames.forEach(name => {
      if (event.target.type == "checkbox") {
        this[name + 'Target'].disabled = !event.srcElement.checked
      } else {
        this[name + 'Target'].disabled = !event.srcElement.value || event.srcElement.value == "0"
      }
    });
  }

  toggleSpellLevels(event) {
    let targetNames = event.currentTarget.dataset.element.split(' ');
    let target, targetInput, targetValue;
    let eventValue = Number(event.srcElement.value);
    let previousValue = eventValue;
    targetNames.forEach(name => {
      target = this[name + 'Target'];
      targetInput = target.querySelector('.spellLevelSlotsInput');
      targetValue = Number(targetInput.value);
      if (previousValue > 0 || targetValue > 0) {
        this[name + 'Target'].classList.remove('hidden')
      } else {
        this[name + 'Target'].classList.add('hidden')
      }
      previousValue = targetValue;
    })
  }

  updateHitPoints(event) {
    this.hitPointsTarget.textContent =
      Math.floor(event.currentTarget.value * event.currentTarget.dataset.perhitdie)
  }

  updateModifier(event) {
    let modifier = Math.floor((event.currentTarget.value - 10) / 2)
    let modifierString = String(modifier)
    if (modifier >= 0) { modifierString = "+" + modifierString }
    let target = this[event.currentTarget.dataset.ability + 'ModifierTarget']
    target.textContent = modifierString
  }

  handleSpecialTraitName(event) {
    const template_description = this.specialTraitDescriptionFor(event.currentTarget.value);

    let target = this['specialTraitEditorDescriptionTarget'];
    target.value = target.value || template_description
  }

  specialTraitDescriptionFor(name) {
    const template = this.specialTraitTemplateFor(name);
    if (template) { return template.description; }
    return ''
  }

  specialTraitTemplateFor(name) {
    return this.specialTraitTemplateData()[this.keyify(name)];
  }

  keyify(token) {
    return token.replaceAll(/\s+/g, '_').replaceAll('-', '_').replaceAll("'", '').toLowerCase();
  }

  specialTraitTemplateData() {
    if (this.specialTraitTemplateDataMemo) { return this.specialTraitTemplateDataMemo }

    const dataNode = document.getElementById('special-trait-data');
    return this.specialTraitTemplateDataMemo = JSON.parse(dataNode.dataset.templates);
  }

  specialTraitTemplateNames() {
    if (this.specialTraitTemplateNamesMemo) { return this.specialTraitTemplateNamesMemo }

    return this.specialTraitTemplateNamesMemo = Object.values(this.specialTraitTemplateData()).map ((templateData) => templateData.name);
  }

  suggestSpecialTraitName(event) {
    const input = event.target;
    if (input && !this.autocomplete) {
      const choices = this.specialTraitTemplateNames();
      this.autocomplete = new autocomplete({
        selector: input,
        minChars: 1,
        source: function(term, suggest){
          term = term.toLowerCase();
          let matches = [];
          for (let i = 0; i < choices.length; i++)
            if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
          suggest(matches);
        },
      });
    }
  }
};
