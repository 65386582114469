import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['light', 'heavy', 'versatile', 'two_handed']

  uncheckMutuallyExclusiveCheckboxes(event) {
    let targetNames = event.currentTarget.dataset.exclusiveElements.split(' ')
    targetNames.forEach(name => {
      if (event.srcElement.checked) {
        this[name + 'Target'].checked = false
      }
    })
  }
}
