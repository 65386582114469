import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reactionTrigger', 'material'];

  toggleDurationSelects(event) {
    let form = event.target.closest('form')
    let durationsSelect = form.getElementsByClassName('spell-form__attribute--duration')[0].getElementsByTagName('select')[0]
    let concentrationDurationsSelect = form.getElementsByClassName('spell-form__attribute--concentration-duration')[0].getElementsByTagName('select')[0]
    if (event.target.checked) {
      durationsSelect.disabled = true
      concentrationDurationsSelect.disabled = false
      durationsSelect.classList.add('hidden')
      concentrationDurationsSelect.classList.remove('hidden')
    } else {
      durationsSelect.disabled = false
      concentrationDurationsSelect.disabled = true
      durationsSelect.classList.remove('hidden')
      concentrationDurationsSelect.classList.add('hidden')
    }
  }

  toggleMaterial(event) {
    if (event.currentTarget.checked) {
      this.materialTarget.disabled = false
    } else {
      this.materialTarget.disabled = true
    }
  }

  toggleReactionTriggerField(event) {
    const castingTimeValue = event.currentTarget.value
    let reactionTrigger = this.reactionTriggerTarget

    if (castingTimeValue == '1 reaction') {
      reactionTrigger.classList.remove('hidden')
    } else {
      reactionTrigger.classList.add('hidden')
    }
  }

  toggleDestructionMarker(event) {
    const shouldKeep = event.currentTarget.checked
    console.log('toggleDestructionMarker')
    console.log('shouldKeep')
    console.log(shouldKeep)

    let destroyField = event.currentTarget.parentElement.getElementsByClassName('spell-form__spell-listing-destroy')[0]
    if (shouldKeep) {
      destroyField.value = '0'
    } else {
      destroyField.value = '1'
    }
    console.log(destroyField)
  }
}
